





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { CORRECT_BEGIN } from '../../../store/actions';
import { ConservatorshipDetails, CorrectBegin } from '../../../types';
import { ApiResponse } from '@/components/types';
import DatePicker from '@/components/form/DatePicker.vue';

const Conservatorship = namespace('conservatorship');

@Component({
  components: {
    DatePicker
  }
})
export default class ConservatorshipBeginCorrectDialog extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;

  @Conservatorship.Action(CORRECT_BEGIN) correctBegin!: (params: CorrectBegin) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: CorrectBegin = {
    conservatorship_id: this.conservatorship.id,
    begin: this.conservatorship.begin,
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        conservatorship_id: this.conservatorship.id,
        begin: format(Date.now(), 'yyyy-MM-dd'),
      };

      return;
    }

    setTimeout(() => {
      // @ts-ignore
      this.$refs.form.reset();
      this.error = null;
    }, 200);
  }

  async save() {
    this.error = null;

    return await this.correctBegin(this.value);
  }
}
